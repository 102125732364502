body {
    font-family: 'PT Sans';
}

@font-face {
    font-family: 'PT Sans';
    src: url(../font/PT_Sans-Web-Regular.ttf);
}

.navigation-bar {
    padding: 23px 0px;
}

.newbtn {
    background: #42b3e5;
    padding: 10px 20px;
    border: none;
    color: #fff;
    width: max-content;
    cursor: pointer;
}

.heading-title {
    text-align: center;
}

h3 {
    font-size: 18px;
}

.heading-title h2 {
    font-size: 24px;
    font-weight: 600;
    font-family: 'PT Sans';
}

.heading-title p {
    color: #666;
}

.banner-img img {
    width: 100%;
}

.first-right-box {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    min-width: 460px;
    min-height: 300px;
    margin-bottom: 20px;
}

.upload-img-set {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 30vh;
}

.upload-btm p {
    color: #666666;
}

.upload-btm a {
    color: #42b3e5;
    text-decoration: none;
    border-bottom: none !important;
}

.center-text-set {
    text-align: center;
}

.center-text-set img {
    margin-bottom: 20px;
}

.center-text-set h4 {
    margin-bottom: 15px;
    border: none;
    color: #000;
    font-size: 18px;
}

.center-text-set h4 a {
    color: #000;
    text-decoration: none;
}

.equal-space {
    padding: 50px 0px;
}

.video-section {
    width: 100%;
    border-radius: 16px;
}

.video-section video {
    width: 100%;
}

.step-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #4C4C4C;
    margin: 20px 0;
    padding: 15px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
}

.step-item .number {
    font-size: 28px;
    font-weight: bold;
    margin-right: 20px;
    transition: all 0.3s ease;
}

.step-item .content {
    flex: 1 1;
    transition: all 0.3s ease;
}

.step-item.active {
    /* transform: scale(1.05); */
    padding: 16px 24px;
    background-color: #fff;
    color: #000;
    border-radius: 20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.step-item.active .number {
    font-size: 60px;
    font-weight: bold;
    margin-right: 20px;
    color: #000;
    transition: all 0.3s ease;
}

.left-img-set img {
    width: 100%;
}

.heading_text h2 {
    font-size: 24px;
    font-weight: 600;
    font-family: 'PT Sans';
}

.heading_text p {
    color: #666666;
}

.banner-img {
    margin-bottom: 20px;
}

.right-text-set {
    display: flex;
    align-items: center;
}

.text_span a {
    text-decoration: none;
}

.upload-area {
    text-align: center;
    border: 2px dashed #ccc;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
}

.upload-area.drag-over {
    background-color: #f0f8ff;
    border-color: #007bff;
}

.upload-area p {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
}

.upload-area button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.upload-area button:hover {
    background: #0056b3;
}

#uploaded-image {
    position: relative;
    text-align: center;
    display: none;
    width: 100%;
}

#uploaded-image img {
    width: 100%;
    max-width: 100%;
    border-radius: 8px;
}

#uploaded-image .close-btn {
    background: #000;
    color: #fff;
    border: none;
    font-size: 20px;
    border-radius: 50%;
    cursor: pointer;
    padding: 0px 9px;
}

.split-btn {
    background-color: #fff;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 16px 0px;
    border-radius: 50%;
    border: none;
    padding: 10px 12px;
}

.split-btn-show {
    background-color: #b4b4b4;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 16px 0px;
    border-radius: 50%;
    border: none;
    padding: 10px 12px;
    display: none;
}

.actions-outside {
    display: none;
    text-align: center;
}

.actions-outside button {
    margin: 0 10px;
    background: #42B3E5;
    color: #fff;
    border: none;
    padding: 15px 30px;
    border-radius: 24px;
    cursor: pointer;
    font-size: 16px;
}

.actions-outside button:hover {
    background: #0384CE;
    color: #fff;
}

.actions-outside button.download-btn:hover {
    background: #1e7e34;
    color: #fff;
}

.upload-image-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.image-item img {
    width: 100%;
}

.upload-btm {
    margin-top: 20px;
}

.drop-set-border {
    border: none !important;
}

.offcanvas-title {
    align-items: center;
    display: flex;
}

/* Plan.css */

.plan-container {
    background-color: #000;
    color: #fff;
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 20px;
}

.plan-title {
    margin-bottom: 20px;
}

.plan-table {
    max-width: 600px;
    margin: 0 auto;
}

.plan-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-weight: bold;
}

.plan-option {
    background-color: #111;
    border: 1px solid #333;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.plan-option.active {
    border: 1px solid #0d6efd;
    color: #0d6efd;
}

.plan-option:hover {
    background-color: #222;
}

.credit-num {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.credit-num h6 {
    font-size: 18px;
    font-weight: 600;
}

.pricing-table {
    max-width: 800px;
    margin: auto;
    border-radius: 25px;
    padding: 40px;
    box-shadow: 0px 0px 10px 0px #b8b7b7;
}

.list-group-item {
    color: #000;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px !important;
    font-size: 18px;
    cursor: pointer;
}

.list-group-item.active {
    background-color: #42b3e5;
    color: #fff;
}

.list-group-item:hover {
    background-color: #42b3e5;
    color: #fff;
}

.btn-primary {
    background-color: #42b3e5;
    border: none;
    font-size: 16px;
    padding: 5px 10px;
}

.plan-table-set {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pricing-table h6 {
    font-weight: 600;
}


.footer_container {
    background-color: #202020;
}

.footer_banner_box.super_light_blue {
    background-color: #42B3E5;
    list-style: none;
}

.footer_banner_box {
    padding: 20px 30px;
    color: #fff;
}

.footer_banner_box h2,
.footer_banner_box h2 a {
    color: #FFF;
    font-size: 24px;
}

.footer_banner_box p {
    /* padding: 1em 0; */
    color: #666;
    margin-bottom: 0;
    /* line-height: 150%; */
}

.icon_small_arrow.right_white {
    background-image: url(../image/icon_img/icon_arrow_margin_right_white.png);
    text-decoration: none;
    list-style: none;
}

.scrolling_list {
    padding-left: 0px;
}


.footer_banner_box a {
    color: #FFF;
    background-position: right center;
    padding-right: 17px;
    border: none;
}

.icon_small_arrow {
    background-position: center center;
    background-repeat: no-repeat;
}

.scrolling_list li {
    width: 312px;
    color: #C5C5C5;
    background-position: left 18px;
    padding: 10px 0 10px 18px;
    line-height: 150%;
}

.footer_banner_box.light_blue {
    background-color: #0384CE;
    list-style: none;
}

.footer_banner_box.blue {
    background-color: #3156A3;
    list-style: none;
}

.footer .box_header {
    color: #FFF;
}

.footer .box_header h3 {
    font-size: 18px;
}

.footer_box p {
    color: #909090;
}

.copyright_area {
    border-top: 1px solid #353535;
    padding: 15px 0;
    font-size: 11px;
    color: #909090;
}

.footer_contact_info_container {
    padding-left: 0px;
}

.social_icons {
    padding-left: 0px;
}

.copyright_left {
    float: left;
}

.copyright_right {
    float: right;
}

/* .clearfix:after {
    font-size: 0px;
    content: ".";
    display: block;
    height: 0px;
    visibility: hidden;
    clear: both;
} */

.copyright_right .scroll_top {
    font-size: 11px;
    color: #C5C5C5;
    background-position: right center;
    padding-right: 15px;
}


.box_header::after {
    content: "";
    display: block;
    bottom: 0;
    width: 50px;
    height: 2px;
    background: #42B3E5;
    margin-top: 10px;
    visibility: hidden;
}

.footer_box_container {
    margin-top: 30px;
}

.footer a {
    color: #D5D5D5;
    text-decoration: none;
}

.icon_small_arrow.top_white {
    background-image: url(../image/icon_img/icon_arrow_top_white.png);
}

.footer .scrolling_list li a {
    color: #D5D5D5;
}

.footer .timeago {
    color: #42B3E5;
}

.timeago {
    clear: both;
    display: block;
    margin-top: 2px;
    font-size: 11px;
    color: #909090;
    text-decoration: none !important;
}

.scrolling_list li {
    margin-bottom: 15px;
}

.footer .header_right a.scrolling_list_control_left,
.footer .header_right a.scrolling_list_control_right {
    border-color: #353535;
}

.header_right a.scrolling_list_control_left,
.header_right a.scrolling_list_control_right,
.pagination li a,
.categories li a,
.scrolling_list li .number {
    border: 1px solid #E0E0E0;
}

.icon_small_arrow.left_white {
    background-image: url(../image/icon_img/icon_arrow_left_white.png);
}

.icon_small_arrow.right_white {
    background-image: url(../image/icon_img/icon_arrow_right_white.png);
}

.header_right {
    float: right;
}

/* .clearfix::after {
    font-size: 0px;
    content: ".";
    display: block;
    height: 0px;
    visibility: hidden;
    clear: both;
} */

.scrolling_list_control_right {
    margin-left: 2px;
}

.scrolling_list_control_left,
.scrolling_list_control_right {
    float: left;
    width: 28px;
    height: 28px;
}

.icon_small_arrow {
    background-position: center center;
    background-repeat: no-repeat;
}

.footer_box {
    margin-top: 30px;
}

.icon_right_left {
    display: flex;
    justify-content: space-between;
}

.social_icons {
    margin-top: 30px;
}

.footer .social_icon.facebook {
    background-image: url(../image/social_footer/facebook.png);
}

.footer .social_icon.twitter {
    background-image: url(../image/social_footer/twitter.png);
}

.footer .social_icon.googleplus {
    background-image: url(../image/social_footer/googleplus.png);
}

.footer .social_icon.mail {
    background-image: url(../image/social_footer/mail.png);
}

a.social_icon:hover {
    transition: background-position .3s ease;
    -moz-transition: background-position .3s ease;
    -webkit-transition: background-position .3s ease;
    -o-transition: background-position .3s ease;
    background-position: 0 -29px;
    text-decoration: none;
}

.social_icon {
    display: block;
    width: 29px;
    height: 29px;
    background-repeat: no-repeat;
    transition: background-position .3s ease;
    -moz-transition: background-position .3s ease;
    -webkit-transition: background-position .3s ease;
    -o-transition: background-position .3s ease;
}

.social_icons li {
    list-style: none;
}

.social_icons li {
    float: left;
    margin-left: 5px;
}

.footer_contact_info_row {
    list-style: none;
}

.footer_contact_info_container {
    color: #909090;
}

.box_header::after {
    content: "";
    display: block;
    bottom: 0;
    width: 50px;
    height: 2px;
    background: #42B3E5;
    margin-top: 10px;
    visibility: hidden;
}

.bg-black-set {
    background-color: #000;
    color: #fff;
}

.colorblue {
    color: #42b3e5;
}

.comparison-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 30px;
    background: transparent;
}

.img-fluid {
    object-fit: cover;
}

/* Slider */
.slider {
    position: absolute;
    width: 1px;
    background: white;
    cursor: ew-resize;
    height: 100%;
    top: 0;
}

.slider-button {
    width: 24px;
    height: 24px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: black;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Thumbnails */
.thumbnail-container {
    display: flex;
    justify-content: center;
}

.thumbnail {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.thumbnail:hover,
.thumbnail.active {
    transform: scale(1.1);
    border: 2px solid blue;
}

.selection-set ::selection {
    background: transparent;
}

@media screen and (max-width: 991px) {
    .equal-space {
        padding: 20px 0px;
    }

    .heading_text {
        margin-top: 20px;
    }

    .bg-black-set {
        margin-bottom: 20px;
    }

    .selection-set {
        margin-bottom: 20px;
    }

    .first-right-box {
        width: 100% !important;
        min-width: 0 !important;
    }

    .right-text-set {
        display: block;
        align-items: center;
    }
}


@media screen and (max-width: 767px) {
    .thumbnail {
        width: 50px;
        height: 50px;
    }

    .btn-primary {
        font-size: 12px;
        padding: 5px 7px;
    }
}